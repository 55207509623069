import {
  alaskaCollection,
  genesisCollection,
  arrlandCollection,
  mayhemCollection,
  sumeragiCollection,
} from '~/assets/images'
import type { nftCardProps } from '~/components/blocks/nft-card'
import { IconName } from '~/tenset-components'

const { Tenset, Discord, GemTglp, GemTpl } = IconName

export const specialUtilities = {
  infinityStaking: 'infinity-staking',
  innerCircle: 'inner-circle',
}

export const nftCards: nftCardProps[] = [
  {
    title: 'Genesis',
    image: genesisCollection,
    linkOpenSea: 'https://opensea.io/collection/tglp-genesis',
    linkOkx:
      'https://www.okx.com/pl/web3/marketplace/nft/collection/eth/tenset-tglp-genesis',
    linkBlur: 'https://blur.io/collection/tglp-genesis',
    utilities: [
      {
        name: 'Tier 5 TGLP access',
        icon: GemTglp,
        url: 'http://localhost:3000/en/gem-launch-platform',
      },
      {
        name: 'Infinity Staking',
        icon: Tenset,
        url: 'https://www.infinity.tenset.io/locks/create',
      },
      {
        name: 'Discord Inner Circle',
        icon: Discord,
        url: 'https://discord.gg/PRavwRHubW',
      },
    ],
    tier: 5,
  },
  {
    title: 'Sumeragi',
    image: sumeragiCollection,
    linkOpenSea: 'https://opensea.io/collection/tglp-sumeragi',
    linkOkx:
      'https://www.okx.com/pl/web3/marketplace/nft/collection/eth/tenset-tglp-sumeragi',
    utilities: [
      {
        name: 'Tier 5 TGLP access',
        icon: GemTglp,
        url: 'http://localhost:3000/en/gem-launch-platform',
      },
      {
        name: 'Infinity Staking',
        icon: Tenset,
        url: 'https://www.infinity.tenset.io/locks/create',
      },
    ],
    tier: 5,
  },
  {
    title: 'Alaska Gold Rush',
    image: alaskaCollection,
    linkOpenSea: 'https://opensea.io/collection/tglp-alaska',
    linkOkx:
      'https://www.okx.com/pl/web3/marketplace/nft/collection/eth/tenset-tglp-alaska',
    utilities: [
      {
        name: 'Tier 4 TGLP access',
        icon: GemTpl,
        url: 'http://localhost:3000/en/gem-launch-platform',
      },
    ],
    tier: 4,
  },
  {
    title: 'Arrland',
    image: arrlandCollection,
    linkOpenSea: 'https://opensea.io/collection/tglp-arrland',
    linkOkx:
      'https://www.okx.com/pl/web3/marketplace/nft/collection/eth/tenset-tglp-arrland',
    utilities: [
      {
        name: 'Tier 4 TGLP access',
        icon: GemTpl,
        url: 'http://localhost:3000/en/gem-launch-platform',
      },
    ],
    tier: 4,
  },
  {
    title: 'Crypto Mayhem',
    image: mayhemCollection,
    linkOkx:
      'https://www.okx.com/pl/web3/marketplace/nft/collection/bsc/tenset-tglp-mayhem',
    utilities: [
      {
        name: 'Tier 4 TGLP access',
        icon: GemTpl,
        url: 'http://localhost:3000/en/gem-launch-platform',
      },
    ],
    tier: 4,
  },
]
