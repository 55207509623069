import clsx from 'clsx'

import {
  Text,
  Image,
  ButtonVariant,
  Button,
  Icon,
  IconName,
} from '~/tenset-components'

const { OKX, Blur, OpenSea } = IconName

export interface renderButtonProps {
  link?: string
  icon: IconName
  label: string
}

export interface utility {
  name: string
  icon: IconName
  url?: string
}

export interface nftCardProps {
  className?: string
  title: string
  image: string
  linkOpenSea?: string
  linkOkx?: string
  linkBlur?: string
  utilities?: utility[]
  tier: number
}

export function NftCard({
  className,
  title,
  image,
  linkOpenSea,
  linkOkx,
  linkBlur,
  utilities,
}: nftCardProps) {
  const buttons = [
    { link: linkOpenSea, icon: OpenSea, label: 'OpenSea' },
    { link: linkOkx, icon: OKX, label: 'OKX' },
    { link: linkBlur, icon: Blur, label: 'Blur' },
  ]

  const renderButton = ({ link, icon }: renderButtonProps) =>
    link && (
      <Button variant={ButtonVariant.Ghost} to={link} isIcon key={link}>
        <Icon name={icon} size={16} />{' '}
      </Button>
    )

  return (
    <div
      className={clsx(
        'group relative flex min-w-[210px] max-w-[250px] flex-col overflow-hidden rounded-[10px] bg-neutral-800',
        className
      )}
    >
      <a
        href={buttons[0].link ?? buttons[1].link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={image} ratio={1} className="rounded-b-none" />
      </a>

      <div className="flex grow flex-col p-4">
        <Text isBold className="mb-2">
          {title}
        </Text>

        <div className="mb-4 flex flex-col gap-1">
          {utilities?.map(({ name, icon, url }) => (
            <Button
              key={name}
              to={url}
              variant={ButtonVariant.Link}
              className="mr-auto"
            >
              <Icon name={icon} size={16} color="#FAFAFA" />
              {name}
            </Button>
          ))}
        </div>

        <div className="mt-auto flex gap-2">
          {buttons.map(button => renderButton(button))}
        </div>
      </div>
    </div>
  )
}
